import React from "react";
import { WrapCommon } from "./WrapCommon";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink
} from "@apollo/client";

export const wrapPageElement = ({
  element,
  props
}: {
  element: React.ReactNode;
  props: any;
}) => {
  const client = new ApolloClient({
    cache: new InMemoryCache({ resultCaching: false }),
    link: new HttpLink({
      uri: process.env.APOLLO_GRAPHQL_URI
    })
  });

  return (
    <WrapCommon props={props}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </WrapCommon>
  );
};
